import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, useTheme, useMediaQuery, SxProps } from '@mui/material';
import { TableHeaderProps, TableListProps } from '../../../@types/TableProps';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setColumnDisplay, setPages } from '../../../store/feature/utilSlice';
import Pagination from '../../molecule/pagination/Pagination';
import Table from '../../molecule/table/Table';
import TableHeader from '../../molecule/tableHeader/TableHeader';

function TableView(
  Props: Omit<
    TableListProps,
    'showShadow' | 'handleSort' | 'page' | 'setPage' | 'showRightShadow'
  > &
    Omit<
      TableHeaderProps,
      'selected' | 'setSelected' | 'handleSelectAll' | 'handleDeselectAll'
    > & {
      pageSize: number;
      totalData: number;
      onInitUncheckedColumn?: Array<string>;
      paginationKey?: string;
      showTotalData?: boolean;
      titleTable?: string;
      anchorPopOver?: any;
      componentBeforeAction?: React.ReactNode;
      showBorderTableContainer?: boolean;
      styleTableContainer?: SxProps;
    }
) {
  const {
    sortBy,
    sortType,
    headerKey,
    rowData,
    setSort,
    showSort = false,
    stickyLeft = false,
    stickyRight = false,
    excludeSelectedColumn = [],
    onClickCreate,
    onFilterClick,
    pageSize,
    totalData,
    activeDescription,
    showAction = false,
    onDescriptionClick,
    showHeader = true,
    showCreate = true,
    showEdit = true,
    showFilter = true,
    showSearch = true,
    showPagination = false,
    excludeSortTable,
    onInitUncheckedColumn,
    actions,
    onClickMenuAction,
    onClickMenuCreate,
    paginationKey = '',
    additionalComponent,
    alterTableHeaderName = {},
    isLoading,
    actionPopoverDirection = 'left',
    createPopoverDirection = 'left',
    createMenu,
    showTotalData = true,
    listCheckedRow,
    showCheckbox,
    handleUnselectAll,
    isAllChecked,
    handleSelectAllCheckbox,
    listUncheckedRow,
    handleClickUncheckRow,
    onClickRowCheckbox,
    limit,
    showBanner,
    customFirstCellHeaderWidth,
    titleTable,
    anchorPopOver,
    showIdx,
    componentBeforeAction,
    showBorderTableContainer = false,
    styleTableContainer = {},
  } = Props;

  const { palette } = useTheme();
  const location = useLocation();
  const pathName = paginationKey
    ? `${location.pathname}/${paginationKey}`
    : location.pathname;
  const dispatch = useAppDispatch();
  const { pages, columnsDisplay, searchLabel, searchString, filter } =
    useAppSelector(state => state.utils);

  const selectedColumn = Object.keys(headerKey)
    .map((key: string) => headerKey[key])
    .filter((key: string) => !onInitUncheckedColumn?.includes(key));

  const transformHeader = React.useMemo(() => {
    const data = { ...headerKey };
    if (columnsDisplay[pathName]) {
      Object.keys(data).forEach(key => {
        if (
          !columnsDisplay[pathName]?.includes(data[key]) &&
          !excludeSelectedColumn.includes(data[key])
        ) {
          delete data[key];
        }
      });
      return data;
    }
    Object.keys(data).forEach(key => {
      if (
        !selectedColumn?.includes(data[key]) &&
        !excludeSelectedColumn.includes(data[key])
      ) {
        delete data[key];
      }
    });
    return data;
  }, [columnsDisplay[pathName], headerKey]);

  const handleSelectAll = () => {
    const selectall = Object.keys(headerKey)
      .map((key: string) => headerKey[key])
      .filter((key: string) => !excludeSelectedColumn.includes(key));
    dispatch(setColumnDisplay({ [pathName]: selectall }));
  };

  const handleDeselectAll = () => {
    dispatch(setColumnDisplay({ [pathName]: [] }));
  };

  const transformSelected = React.useMemo(() => {
    const data = { ...headerKey };
    Object.keys(data).forEach(key => {
      if (excludeSelectedColumn.includes(data[key])) {
        delete data[key];
      }
    });
    return data;
  }, []);

  const matches = useMediaQuery('(max-width:1023px)');

  useEffect(() => {
    if (
      !pages[pathName] &&
      !searchLabel[pathName] &&
      !searchString[pathName] &&
      !filter[pathName] &&
      showPagination
    ) {
      // dispatch(resetPages(pathName));
      // dispatch(resetFilter(pathName));
      // dispatch(resetSearch(pathName));
    }
  }, [pathName]);

  return (
    <Box
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {titleTable && (
        <Typography variant="body18" color={palette.gray[90]} fontWeight="700">
          {titleTable}
        </Typography>
      )}
      {showHeader && (
        <TableHeader
          headerKey={transformSelected}
          selected={columnsDisplay[pathName] || selectedColumn}
          setSelected={(value: any) => {
            dispatch(setColumnDisplay({ [pathName]: value }));
          }}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          onClickCreate={onClickCreate}
          onFilterClick={onFilterClick}
          showAction={showAction}
          showHeader={showHeader}
          actions={actions}
          onClickMenuAction={onClickMenuAction}
          showCreate={showCreate}
          createMenu={createMenu}
          onClickMenuCreate={onClickMenuCreate}
          showEdit={showEdit}
          showFilter={showFilter}
          showSearch={showSearch}
          additionalComponent={additionalComponent}
          paginationKey={paginationKey}
          actionPopoverDirection={actionPopoverDirection}
          createPopoverDirection={createPopoverDirection}
          showBanner={showBanner}
          componentBeforeAction={componentBeforeAction}
        />
      )}
      <Table
        sortBy={sortBy}
        sortType={sortType}
        headerKey={transformHeader}
        rowData={rowData}
        setSort={setSort}
        showSort={showSort}
        stickyLeft={matches ? false : stickyLeft}
        stickyRight={matches ? false : stickyRight}
        activeDescription={activeDescription}
        onDescriptionClick={onDescriptionClick}
        excludeSortTable={excludeSortTable}
        page={pages?.[pathName] || 1}
        alterTableHeaderName={alterTableHeaderName}
        isLoading={isLoading}
        listCheckedRow={listCheckedRow}
        showCheckbox={showCheckbox}
        handleUnselectAll={handleUnselectAll}
        isAllChecked={isAllChecked}
        handleSelectAllCheckbox={handleSelectAllCheckbox}
        listUncheckedRow={listUncheckedRow}
        handleClickUncheckRow={handleClickUncheckRow}
        onClickRowCheckbox={onClickRowCheckbox}
        limit={limit}
        customFirstCellHeaderWidth={customFirstCellHeaderWidth}
        showIdx={showIdx}
        showBorderTableContainer
        styleTableContainer={styleTableContainer}
      />
      {(showPagination || pageSize === 0) && (
        <Pagination
          showTotalData={showTotalData}
          key={pages[pathName]}
          page={pages?.[pathName] || 1}
          defaultPage={pages?.[pathName] || 1}
          count={pageSize}
          totalData={totalData}
          onChange={(e: any, value: any) => {
            dispatch(setPages({ [pathName]: value }));
          }}
          limit={limit}
        />
      )}
    </Box>
  );
}

export default TableView;
