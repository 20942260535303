const ClockHistory = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.43359 7.66667L2.76653 9L4.10026 7.66667M2.60351 8.66667C2.57932 8.44778 2.56689 8.22534 2.56689 8C2.56689 4.68629 5.25319 2 8.56689 2C11.8806 2 14.5669 4.68629 14.5669 8C14.5669 11.3137 11.8806 14 8.56689 14C6.68205 14 5.0002 13.1309 3.90023 11.7716M8.10026 4.66667V8L10.1003 9.33333"
      stroke="#8A98A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClockHistory;
