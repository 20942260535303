import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ESSContainer } from '../../components/template';
import { EmployeeBoxContainer, LatestPayslipContainer } from './containers';
import { useGetESSProfileQuery } from '../../store/feature/service/essEmployee';
import { essEmployeeConstants } from '../../constants';

const LatestPayslip = () => {
  const { palette } = useTheme();
  const style: SxProps = {
    '& .header': {
      mb: '2rem',
    },
    '& .content': {
      display: 'flex',
      gap: '64px',
    },
    '& .font-bold': {
      fontWeight: 700,
    },
    '& .font-normal': {
      fontWeight: 400,
    },
    '& .activity-description': {
      mt: '0.5rem',
      fontWeight: 400,
    },
    '& .payslip-container': {
      flex: 1,
    },
  };

  const { data } = useGetESSProfileQuery({});

  return (
    <ESSContainer sx={style}>
      <Box>
        <Box className="header">
          <Typography
            className="font-bold"
            variant="h5"
            color={palette.gray[100]}
          >
            Hi, {data?.fullName || ''}
          </Typography>
          <Typography
            className="activity-description"
            variant="body14"
            color={palette.gray[100]}
          >
            What's your activity today?
          </Typography>
        </Box>
        <Box className="content">
          <LatestPayslipContainer
            className="payslip-container"
            latestPayslipDate="Jun 2023"
          />
          <EmployeeBoxContainer data={data} />
        </Box>
      </Box>
    </ESSContainer>
  );
};

export default LatestPayslip;
