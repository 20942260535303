import React from 'react';
import { RouteNode } from '../../@types';

const ListRequest = React.lazy(
  () => import('../../pages/employee/requestTimeoffDetails/List')
);

const ListOvertime = React.lazy(
  () => import('../../pages/employee/requestOvertimeDetails/List')
);

const timeOff: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/request-details',
    component: ListRequest,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/overtime-history-details',
    component: ListOvertime,
    child: null,
  },
];

export default timeOff;
