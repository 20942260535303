import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/atom';
import { ChevronRight } from '../../../components/atom/icon';
import { Payslips } from '../components';
import { mergeDeep } from '../../../utils/deepMerge';
import { useGetESSLatestPayslipQuery } from '../../../store/feature/service/essEmployee';

type LatestPayslipContainerProps = {
  sx?: SxProps;
  onClickSeeAll?: Function;
  latestPayslipDate: string;
  className?: string;
};

const LatestPayslipContainer = ({
  latestPayslipDate,
  onClickSeeAll,
  sx,
  className,
}: LatestPayslipContainerProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    '& .payslip-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: '20px',
    },
    '& .right-arrow': {},
  };
  const { data: latest } = useGetESSLatestPayslipQuery({});
  console.log('ini latest', latest);
  const navigate = useNavigate();
  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <Box className="payslip-title">
        <Typography
          className="font-bold"
          variant="body18"
          color={palette.gray[100]}
        >
          Latest Payslip
        </Typography>
        {latest?.isMoreThanOne && (
          <Button
            variant="link"
            onClick={() => navigate('/ess/employee/payslips')}
          >
            See all <ChevronRight className="right-arrow" />
          </Button>
        )}
      </Box>
      <Payslips
        date={latest?.latestPayslip?.name || ''}
        id={latest?.latestPayslip?.id || ''}
        isLatest
      />
    </Box>
  );
};

export default LatestPayslipContainer;
